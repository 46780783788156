<template>
  <div class="Auditservepar">
    <div class="top-titles">
      <div class="title"><span>待审核招聘信息</span>/待审核招聘详情</div>

      <div class="top-box-images">
        <img
          class="top-box-images-image"
          v-for="(item, i) in listpar.profile_photos"
          :key="i"
          :src="item"
          alt=""
        />
      </div>
    </div>

    <!-- 详情-->
    <div class="forms">
      <el-form
        ref="listpar"
        :model="listpar"
        :rules="rules"
        label-width="120px"
        enctype="multipart/form-data"
      >
        <div class="Newsinvite-form">
          <el-form-item label="标题：" prop="headline">
            <el-input
              v-model="listpar.headline"
              auto-complete="true"
            ></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="工种：" prop="kind">
            <el-input
              v-model="listpar.kind"
              auto-complete="true"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </div>
        <div class="Newsinvite-form">
          <el-form-item label="工作性质：" prop="work">
            <el-input
              v-model="listpar.work"
              auto-complete="true"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item
            label="工价："
            prop="salary"
            v-if="listpar.work != '长期工'"
          >
            <el-input-number
              type="number"
              :min="0"
              v-model="listpar.salary"
              auto-complete="true"
            ></el-input-number
            >&nbsp;元/时
          </el-form-item>
          <el-form-item label="招聘人数：" prop="recruitingNumbers">
            <el-input-number
              type="number"
              :min="0"
              v-model="listpar.recruitingNumbers"
              @change="calculate()"
              @blur="calculate()"
              auto-complete="true"
            ></el-input-number
            >&nbsp;人
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item
            label="起始工作日期"
            prop="startTime"
            v-if="listpar.work != '长期工'"
          >
            <el-date-picker
              v-model="listpar.startTime"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              @blur="calculate()"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束工作日期" prop="finishTime">
            <el-date-picker
              v-model="listpar.finishTime"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item label="起始工作时间" prop="startHour">
            <el-time-select
              v-model="listpar.startHour"
              auto-complete="true"
              :picker-options="{ start: '05:00', step: '00:10', end: '23:30' }"
              placeholder="选择时间"
              @blur="calculate()"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item label="结束工作时间" prop="finisHour">
            <el-time-select
              v-model="listpar.finisHour"
              auto-complete="true"
              :picker-options="{ start: '05:00', step: '00:10', end: '23:30' }"
              placeholder="选择时间"
              @blur="calculate()"
            >
            </el-time-select>
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item
            :label-width="i == 0 ? '80px' : '10px'"
            :label="i == 0 ? '招聘要求' : ''"
            v-for="(item, i) in listpar.askFor"
            :key="i"
          >
            <el-input v-model="item.yaoqiu"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item label="工厂名称：" prop="companyName">
            <el-input
              v-model="listpar.companyName"
              auto-complete="true"
            ></el-input>
          </el-form-item>

          <el-form-item label="工厂地址：" prop="companyAddress">
            <el-input
              v-model="listpar.companyAddress"
              auto-complete="true"
            ></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item label="联系人：" prop="linkman">
            <el-input v-model="listpar.linkman" auto-complete="true"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="phone">
            <el-input v-model="listpar.phone" auto-complete="true"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item label="月综合工资：">
            <el-input v-model="listpar.month_multiple_price"></el-input>
          </el-form-item>

          <el-form-item label="支付招聘费用">
            <el-input-number
              type="number"
              :min="0"
              v-model="listpar.price_toplat"
              auto-complete="true"
              @change="calculate()"
              @blur="calculate()"
            ></el-input-number
            >&nbsp;元/<span v-if="listpar.work == '长期工'">月</span
            ><span v-else>时</span>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="支付招聘总费用：" label-width="180px">
            <el-input
              v-model="listpar.promise_payplat_total"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form" v-if="logintype == 1 ? true : false">
          <el-form-item label="超级会员单位佣金：" label-width="180px">
            <el-input
              v-model="listpar.share_commission_price0"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form" v-if="logintype == 1 ? true : false">
          <el-form-item label="超级会员外挂单位佣金：" label-width="180px">
            <el-input
              v-model="listpar.share_commission_price1"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form" v-if="logintype == 1 ? true : false">
          <el-form-item label="经理单位佣金：">
            <el-input
              v-model="listpar.share_commission_price2"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              v-model="listpar.remark"
              style="width: 600px"
              rows="3"
            ></el-input>
          </el-form-item>
        </div>

        <div style="width: 100%; padding-bottom: 50px">
          <span>职业描述：</span>
          <Fwbs v-model="listpar.job" :isClear="isClear"></Fwbs>
        </div>

        <div class="particulars-map" v-if="flag">
          工作地址：<Maps :weizhi="listpar" @jingwei="jingwei" />
        </div>

        <div class="dizhi-box">
          <div class="dizhi-li">
            <el-form-item label="查找位置:" prop="work_addr1">
              <el-input
                v-model="listpar.work_addr1"
                auto-complete="true"
                placeholder="请输入具体工作地址"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              class="biy-icon"
              icon="el-icon-check"
              @click="tijiao()"
              >查询</el-button
            >
          </div>
          <div class="dizhi-li" style="color: red; font-size: 12px">
            具体地址查询须精准到市级下辖行政区域
          </div>
        </div>
        <!--  <div class="form-btn">
          <el-button
            type="primary"
            class="biy-icon"
            icon="el-icon-check"
            @click="submitForm('listpar')"
            >发布招聘信息</el-button
          >
          <el-button
            type="primary"
            class="biy-icon"
            icon="el-icon-check"
            @click="tijiaoBtn()"
            >存入到草稿箱</el-button
          >
        </div> -->
      </el-form>
    </div>
    <div class="particulars">
      <!-- 
        <div class="particulars-list">
          <span class="pl-tltle">招聘要求:</span>
          <span class="pl-content pl-content-i"
            ><i v-for="(item, i) in listpar.askFor" :key="i">{{
              item.yaoqiu
            }}</i></span
          >
        </div> -->

      <div
        class="unamortizedpar-affirm"
        v-if="this.$common.baseGetSeparate() == 1 ? true : false"
      >
        <div>
          <el-button
            type="info"
            class="biy-icon"
            @click="dialogFormVisible = true"
            >驳回</el-button
          >
        </div>
        <div>
          <el-button type="success" class="biy-icon" @click="tongyi()"
            >同意</el-button
          >
        </div>
        <div>
          <el-button type="primary" class="biy-icon" @click="edit()"
            >编辑</el-button
          >
        </div>
      </div>

      <el-dialog
        title="请输入驳回理由"
        :visible.sync="dialogFormVisible"
        width="450px"
      >
        <el-form :model="listpar" label-width="80px">
          <el-form-item label="驳回理由">
            <el-input v-model="listpar.liyou"></el-input>
          </el-form-item>
        </el-form>
        <div div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="bohui()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Maps from "@/components/maps/Maps.vue";
import fakelist from "@/api/fakelist.js";
import apiurl from "@/api/apiurl/apiurl.js";
import config from "@/request/config.js";
import Fwbs from "@/components/fwb/Fwb.vue";
export default {
  name: "maps",
  components: { Maps, Fwbs },
  data() {
    return {
      // 上传图片的地址
      upLoadUrl: config.ImgUrl,
      // 效验
      rules: fakelist.rules,
      // 富文本的值
      isClear: false,
      listpar: {},
      weizhi: "",
      list: [],
      dialogFormVisible: false,

      // 子组件等待父组件接口调通再加载
      flag: false,
      logintype: this.$common.baseGetSeparate(),
    };
  },

  created() {
    this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
    async getSong() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家
        handleMode: "fetch",
        bizCatalog: "RecruitEntity",
        bizId: this.$route.params.id,
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.listpar = res.Result;
      console.log(this.listpar);
      if (this.listpar.askFor.length == 0) {
        this.listpar.askFor = [{}, {}, {}, {}, {}];
      }
      this.listpar.zoom = 15;
      this.flag = true;
    },

    // 驳回接口
    async bohui() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家

        bizCatalog: "RecruitEntity",
        handleMode: "audit",
        bizId: this.$route.params.id,
        todo: "refuse", //allow 同意/refuse 拒绝
        reason: this.listpar.liyou,
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.$alert("驳回成功", "消息提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$router.push({ path: `/home/lookinvite` });
        },
      });
    },

    //同意接口
    tongyi() {
      this.$confirm("是否确认同意", "消息提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "取消操作",
      })
        .then(() => {
          this.tongyis();
        })
        .catch((action) => {
          console.log("取消");
        });
    },

    //同意接口
    async tongyis() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家

        bizCatalog: "RecruitEntity",
        handleMode: "audit",
        bizId: this.$route.params.id,
        todo: "allow", //allow 同意/refuse 拒绝
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.$alert("成功同意", "消息提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$router.push({ path: `/home/lookinvite` });
        },
      });
    },

    // 输入框失去焦点时计算中费用
    calculate() {
      this.calculates();
    },

    async calculates() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家
        bizCatalog: "RecruitEntity",
        handleMode: "getNewPayplatTotal",
        bizData: {
          id: this.listpar.id,
          price_toplat: this.listpar.price_toplat,
          begin_datetime: this.listpar.startTime,
          finish_datetime: this.listpar.finishTime,
          begin_hour: this.listpar.startHour,
          finish_hour: this.listpar.finisHour,
          need_total: this.listpar.recruitingNumbers,
        },
      });
      this.listpar.promise_payplat_total = res.Result; //支付招聘总费用
    },

    //经纬度
    jingwei(val) {
      this.weizhi = val.addressComponents;

      this.listpar.longitude = val.location.lng;
      this.listpar.latitude = val.location.lat;
    },
    // 获取地图检索
    tijiao() {
      this.flag = false;
      //  input失焦获取填写地址
      let that = this;
      $.ajax({
        type: "get",
        dataType: "jsonp",
        data: {
          key: "EO2BZ-I3CAV-HO7PP-UT2A3-XVWW7-ECBF4", // 填申请到的腾讯key
          address: that.listpar.work_addr1, //具体的地址
          output: "jsonp", //返回格式：支持JSON/JSONP，默认JSON
        },
        jsonp: "callback",
        jsonpCallback: "QQmap",
        url: "https://apis.map.qq.com/ws/geocoder/v1/?",
        success: function (json) {
          if (json.status == 0) {
            that.listpar.longitude = json.result.location.lng.toString();
            that.listpar.latitude = json.result.location.lat.toString();
            that.weizhi = json.result["address_components"];
            if (json.result.reliability.toString() == 1) {
              that.listpar.zoom = 7;
            } else {
              that.listpar.zoom = 14;
            }

            that.flag = true;

            that.$message({
              message: "成功获取位置的经纬度",
              type: "success",
            });
          } else {
            that.$message.error("获取该位置经纬度失败");
            that.flag = true;
          }
        },
        error: function (err) {
          that.flag = true;
          that.$message.error("异常错误，请刷新浏览器后重试");
        },
      });
    },

    //编辑接口
    edit() {
      console.log(this.listpar.profile_photos);
      this.$confirm("是否确认编辑？", "消息提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "取消操作",
      }).then(() => {
        this.edits();
      });
    },
    async edits() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家

        bizCatalog: "RecruitEntity",
        handleMode: "edit",
        bizId: this.$route.params.id,
        todo: "allow", //allow 同意/refuse 拒绝
        title: this.listpar.headline, //标题
        profile_photos: this.listpar.profile_photos, //图片
        need_total: this.listpar.recruitingNumbers, //招聘人数
        price: this.listpar.salary, //工价
        price_toplat: this.listpar.price_toplat, //支付招聘费用
        promise_payplat_total: this.listpar.promise_payplat_total, //支付招聘总费用
        month_multiple_price: this.listpar.month_multiple_price, //月综合工资
        begin_datetime: this.listpar.startTime, //开始日期
        finish_datetime: this.listpar.finishTime, //结束日期
        begin_hour: this.listpar.startHour, //开始时间
        finish_hour: this.listpar.finisHour, //结束时间
        requirements: JSON.stringify(this.listpar.askFor), //招聘要求
        work_detail: this.listpar.job, //职业描述
        work_remark: this.listpar.remark, //备注
        employer_name: this.listpar.companyName, //工厂名称
        linker_name: this.listpar.linkman, //联系人
        linker_phone: this.listpar.phone, //联系电话
        employer_addr: this.listpar.companyAddress, //工厂地址
        work_addr: this.weizhi,
        work_addr1: this.listpar.work_addr1, //手动输入地址
        work_addr_lng: this.listpar.longitude,
        work_addr_lat: this.listpar.latitude,
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.$alert("成功编辑", "消息提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$router.push({ path: `/home/lookinvite` });
        },
      });
    },

    //编辑接口
  },
  computed: {},
};
</script>


<style  scoped>
/* 待审核商家按钮 */
.unamortizedpar-affirm {
  height: 70px;
  line-height: 70px;
  width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding: 100px 0 200px 0;
}
.unamortizedpar-affirm .biy-icon {
  width: 100px;
  border-radius: 20px;
}

.top-box-images {
  height: 140px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
}
.top-box-images .top-box-images-image {
  height: 120px;
  width: auto;
  margin: 0 auto;
}
/* @import "../../assets/css/parstyle.css"; */

/* 搜索栏样式 */
.top-titles {
  width: 94%;
  margin: 0 auto;
  height: 70px;
}
.top-text {
  height: 30px;
  font-size: 18px;
}

.forms {
  width: 800px;
  margin: 120px auto 0;
}
.Newsinvite-form {
  width: 400px;
  /* margin: 0 auto; */
}
.Newsinvite-form.two {
  width: 700px;
  display: flex;
  /* margin: 0 auto; */
}
.Newsinvite-form.two .biy-icon {
  height: 40px;
  margin-left: 40px;
}

.Newsinvite-form.duoge {
  width: 700px;
  display: flex;
  justify-content: space-between;
  /* margin: 0 auto; */
}
.form-btn {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 100px;
}
.form-btn .biy-icon {
  width: 250px;
  border-radius: 10px;
  background-color: #2ab6708a;
}
.form-btn .biy-icon:nth-of-type(1) {
  background-color: #01847f;
}
/* 地址 */
.dizhi-box {
  padding-top: 20px;
  width: 100%;
}
.dizhi-li {
  width: 800px;
  margin: 0 auto;
  height: 60px;
  display: flex;
  justify-content: center;
}
.dizhi-li .biy-icon {
  height: 40px;
}

.Insurance-box {
  padding-top: 30px;
  width: 100%;
  margin: 0 auto;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 50px;
  position: relative;
}
.form-btn {
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.form-btn .biy-icon {
  width: 300px;
}

/* 上传 */
.top-box-image {
  text-align: center;
  /* height: 100px; */
  font-size: 15px;
  overflow: hidden;
  display: flex;
  line-height: 100px;
  padding-left: 50px;
}
.image-btn {
  text-align: right;
  line-height: 50px;
}
.image-btn p:nth-of-type(2) {
  cursor: pointer;
  border: 1px dashed #999;
}

.particulars-map {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>